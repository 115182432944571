<template>
  <scroller
    :on-refresh="refresh"
  >
    <h1 class="text-primary mt-2 mb-0">
      {{ $t('pages.plots.name') }}
    </h1>
    <p class="text-black-25 mt-0 mb-0 pb-50">
      {{ lastPullFormat !== null ? $t('therms.refreshed_at') + ' ' + lastPullFormat : $t('therms.loading') }}
    </p>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="error">
        <div
          class="mt-2 mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.plots.list.loading-error') }}.

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="refresh(() => {}, true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!isFetch">
          <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <template
            v-if="has"
          >
            <div class="mt-2 d-flex align-items-center justify-content-between">
              <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
                <nav-button
                  class="mr-75"
                  color="primary"
                  @action="goToAdd"
                >
                  <i class="fa-regular fa-plus" />
                </nav-button>
                <span>{{ $t('therms.add') }}</span>
              </NavButtonBar>
            </div>

            <div class="mt-1 pb-50" />

            <template v-if="hasGroup">
              <template v-for="group in hierarchicalList">
                <div
                  :key="group.id"
                >
                  <h7
                    class="mb-75 pt-25 text-ellipsis"
                  >
                    {{ $t('therms.plot')}} {{ group.name }}
                  </h7>
                  <b-list-group
                    class="mb-2"
                  >
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      @action="goToOne(group.id)"
                    >
                      <span class="text-ellipsis">
                        {{ group.name }} {{ (group.abbreviation+'').toLowerCase() !== (group.name+'').toLowerCase() ? '(' + group.abbreviation + ')' : '' }}
                      </span>
                      <span class="col-2 d-flex justify-content-end m-0 p-0">
                        <b-list-group-item-button
                          disable
                          color="primary"
                        >
                          <i class="fa-solid fa-angle-right" />
                        </b-list-group-item-button>
                      </span>
                    </b-list-group-item>

                    <b-list-group-item
                      v-for="item in group.subplots"
                      :key="item.id"
                      class="d-flex justify-content-between align-items-center"
                      @action="goToOne(item.id)"
                    >
                      <span class="text-ellipsis">
                        {{ item.name }} {{ (item.abbreviation+'').toLowerCase() !== (item.name+'').toLowerCase() ? '(' + item.abbreviation + ')' : '' }}
                      </span>
                      <span class="col-2 d-flex justify-content-end m-0 p-0">
                        <b-list-group-item-button
                          disable
                          color="primary"
                        >
                          <i class="fa-solid fa-angle-right" />
                        </b-list-group-item-button>
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </template>
            </template>

            <template v-else>
              <b-list-group
                class="mb-2"
              >
                <b-list-group-item
                  v-for="item in list"
                  :key="item.id"
                  class="d-flex justify-content-between align-items-center"
                  @action="goToOne(item.id)"
                >
                  <span class="text-ellipsis">
                    {{ item.name }} {{ (item.abbreviation+'').toLowerCase() !== (item.name+'').toLowerCase() ? '(' + item.abbreviation + ')' : '' }}
                  </span>
                  <span class="col-2 d-flex justify-content-end m-0 p-0">
                    <b-list-group-item-button
                      disable
                      color="primary"
                    >
                      <i class="fa-solid fa-angle-right" />
                    </b-list-group-item-button>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </template>

          </template>
          <template v-else>
            <div class="text-center px-50">
              <span style="font-size: 6.5em">
                🙉
              </span>
              <h7 class="mt-1">
                {{ $t('pages.plots.list.no-plots') }}
              </h7>
              <p class="text-black-50 mt-0 pb-50">
                {{ $t('pages.plots.list.no-data') }}
              </p>
              <div>
                <TouchButton
                  size="xs"
                  class="mb-2"
                  @action="goToAdd"
                >
                  {{ $t('therms.start') }}
                </TouchButton>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import { BListGroup, BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import { NAME_PLOT_ADD, NAME_PLOT_ONE } from '@/router/routes/plots'

export default {
  components: {
    TouchButton,
    NavButton,
    NavButtonBar,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,

      // data
    }
  },
  computed: {
    ...mapGetters({
      has: 'plots/has',
      isFetch: 'plots/isFetch',
      hasGroup: 'plots/hasGroup',
      lastPull: 'plots/getLastPull',
      list: 'plots/getList',
      hierarchicalList: 'plots/getHierarchicalList',
    }),
    /**
     * @returns {string|null}
     */
    lastPullFormat() {
      if (this.lastPull === null) return null
      return (`${this.lastPull?.format('LT')}`).toLowerCase()
    },
  },
  async mounted() {
    this.error = false

    this.emitter.on('appStateChange', async isActive => {
      if (this.needFetch(isActive)) await this.fetch(true)
    })

    try {
      if (this.needFetch(true)) await this.fetch(true)
    } catch (err) {
      this.error = true
      throw err
    }
  },
  beforeDestroy() {
    this.emitter.off('appStateChange')
  },
  methods: {
    goToAdd() {
      this.$router.push({
        name: NAME_PLOT_ADD,
      })
    },
    goToOne(id) {
      this.$router.push({
        name: NAME_PLOT_ONE,
        params: {
          id,
        },
      })
    },
    /**
     * @param {Boolean} isActive
     * @returns {boolean}
     */
    needFetch(isActive = false) {
      if (this.$store.getters['plots/getLastPull'] === null) return true

      return (isActive && (this.$store.getters['plots/getLastPull'].format('YYYY-MM-DD') !== this.$moment()
        .format('YYYY-MM-DD')
          || this.$moment()
            .diff(this.$store.getters['plots/getLastPull'], 'minutes') >= 5))
    },
    /**
     * @param {Boolean} force
     */
    async fetch(force = false) {
      if (force) {
        this.$store.commit('plots/clear')
      }

      await this.$store.dispatch('plots/fetch')
    },
    /**
     * @param {Function} finishToRefresh
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    // eslint-disable-next-line no-unused-vars
    async refresh(finishToRefresh, force = false) {
      this.error = false

      try {
        if (force) this.$store.commit('plots/clear')
        await this.$store.dispatch('plots/fetch')
        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
      finishToRefresh()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
